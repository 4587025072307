import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { TemplateProject } from '../../templates';
export const meta = {
  title: 'RankSense',
  hint: 'RankSense SaaS application and browser extension',
  order: 16,
  type: 'project',
  published: true,
  seoTitle: 'RankSense',
  seoDescription: 'RankSense - UX design for a SaaS application and a browser extension.'
};
export const content = {
  images: {
    hero: {
      src: '/images/ranksense/ranksense-intro.jpg',
      alt: 'SEO Monitoring Tool | RankSense',
      density: [1, 2, 4],
      breakpoint: ['s', 'm', 'l']
    },
    bottom: {
      src: '/images/ranksense/ranksense-end.jpg',
      alt: 'Responsive Design - UX Design | RankSense',
      density: [1, 2, 4],
      breakpoint: ['s', 'm', 'l']
    },
    thumbnail: {
      src: '/images/ranksense/ranksense-thumb.jpg',
      alt: 'RankSense',
      density: [1, 2, 4]
    }
  },
  tags: ['SaaS', 'app', 'responsive', 'UX', 'UI', 'SEO', 'analytics'],
  excerpt: 'RankSense is a system that monitors a client’s website, detects SEO issues and proposes solutions. It’s been featured in the Cloudflare App Store.',
  quote: 'ranksense',
  description: 'RankSense is a system that monitors a client’s website, detects SEO issues and proposes solutions. The aim was to produce amazing SEO results in just 6 weeks instead of the usual 6 months. With excellent real-world results, RankSense became a featured app in the Cloudflare App Store.',
  problem: 'For website owners, SEO is a struggle. Owners don’t have the best tools to measure the impact of their changes, can’t implement fixes fast enough and are always waiting for the next development cycle. And finally, owners are usually forced to do all their SEO work manually.',
  solution: 'We designed a tool that creates solutions to SEO issues including auto-generating meta descriptions from content using AI. The user can tweak results and implement via the Cloudflare platform without a development team.',
  figures: [{
    value: '18 weeks',
    label: 'Faster SEO results delivery compared with manual work'
  }, {
    value: '+30%',
    label: 'Faster client self-service deployment'
  }, {
    value: '70%',
    label: 'Savings in RankSense infrastructure costs'
  }, {
    value: '$100,000',
    label: 'Google Cloud Startups Program Grant'
  }]
};
export const _frontmatter = {};
const layoutProps = {
  meta,
  content,
  _frontmatter
};
const MDXLayout = TemplateProject;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAQAF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAdyFGav/xAAWEAADAAAAAAAAAAAAAAAAAAABECD/2gAIAQEAAQUCQj//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAY/Al//xAAcEAACAgIDAAAAAAAAAAAAAAABEQAhMUEQUaH/2gAIAQEAAT8hOd17Ee9RkHDZsR3iuP/aAAwDAQACAAMAAAAQTB//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAWEQADAAAAAAAAAAAAAAAAAAABEBH/2gAIAQIBAT8QhX//xAAaEAEBAQEBAQEAAAAAAAAAAAABESEAUUFx/9oACAEBAAE/EAxEBrl/HCEUDSjo8k0V3BOQWsaPj71hHQanzjSvf//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/53c4941511aaa258461b5e6df54c3ef9/d2432/ranksense-users.webp 314w", "/static/53c4941511aaa258461b5e6df54c3ef9/41786/ranksense-users.webp 628w", "/static/53c4941511aaa258461b5e6df54c3ef9/d4599/ranksense-users.webp 1256w", "/static/53c4941511aaa258461b5e6df54c3ef9/72bc3/ranksense-users.webp 1884w", "/static/53c4941511aaa258461b5e6df54c3ef9/51747/ranksense-users.webp 2512w", "/static/53c4941511aaa258461b5e6df54c3ef9/4e71a/ranksense-users.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/53c4941511aaa258461b5e6df54c3ef9/7a743/ranksense-users.jpg 314w", "/static/53c4941511aaa258461b5e6df54c3ef9/6f105/ranksense-users.jpg 628w", "/static/53c4941511aaa258461b5e6df54c3ef9/10d53/ranksense-users.jpg 1256w", "/static/53c4941511aaa258461b5e6df54c3ef9/b2e4b/ranksense-users.jpg 1884w", "/static/53c4941511aaa258461b5e6df54c3ef9/fdcb3/ranksense-users.jpg 2512w", "/static/53c4941511aaa258461b5e6df54c3ef9/78bd4/ranksense-users.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/53c4941511aaa258461b5e6df54c3ef9/10d53/ranksense-users.jpg",
            "alt": "User Research - UX Design | RankSense",
            "title": "User Research - UX Design | RankSense",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAIF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB3JslAf/EABgQAAIDAAAAAAAAAAAAAAAAABARARJB/9oACAEBAAEFAtupCH//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAWEAADAAAAAAAAAAAAAAAAAAAAESD/2gAIAQEABj8CFP8A/8QAGRABAAMBAQAAAAAAAAAAAAAAAQARIRBx/9oACAEBAAE/IfUTAStlFGtOf//aAAwDAQACAAMAAAAQF8//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAZEAEAAwEBAAAAAAAAAAAAAAABABFBECH/2gAIAQEAAT8QLV6oyEaXkVgwQli8jB5//9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/b5ce579293cf633c113670f4906a3a96/d2432/ranksense-products.webp 314w", "/static/b5ce579293cf633c113670f4906a3a96/41786/ranksense-products.webp 628w", "/static/b5ce579293cf633c113670f4906a3a96/d4599/ranksense-products.webp 1256w", "/static/b5ce579293cf633c113670f4906a3a96/72bc3/ranksense-products.webp 1884w", "/static/b5ce579293cf633c113670f4906a3a96/51747/ranksense-products.webp 2512w", "/static/b5ce579293cf633c113670f4906a3a96/4e71a/ranksense-products.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/b5ce579293cf633c113670f4906a3a96/7a743/ranksense-products.jpg 314w", "/static/b5ce579293cf633c113670f4906a3a96/6f105/ranksense-products.jpg 628w", "/static/b5ce579293cf633c113670f4906a3a96/10d53/ranksense-products.jpg 1256w", "/static/b5ce579293cf633c113670f4906a3a96/b2e4b/ranksense-products.jpg 1884w", "/static/b5ce579293cf633c113670f4906a3a96/fdcb3/ranksense-products.jpg 2512w", "/static/b5ce579293cf633c113670f4906a3a96/78bd4/ranksense-products.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/b5ce579293cf633c113670f4906a3a96/10d53/ranksense-products.jpg",
            "alt": "Product Design - UX Design | RankSense",
            "title": "Product Design - UX Design | RankSense",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAEDAgX/xAAVAQEBAAAAAAAAAAAAAAAAAAABAP/aAAwDAQACEAMQAAAB7LeEqSC//8QAGhAAAgMBAQAAAAAAAAAAAAAAAAECESEiMf/aAAgBAQABBQJWaR80muYN1//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABgQAAIDAAAAAAAAAAAAAAAAAAABICFx/9oACAEBAAY/AnsGWj//xAAcEAADAQACAwAAAAAAAAAAAAAAATERIUFRYXH/2gAIAQEAAT8hx5P0N8jVUSbZRmzUh06/p//aAAwDAQACAAMAAAAQW/8A/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAh/9oACAEDAQE/EHIS/8QAFREBAQAAAAAAAAAAAAAAAAAAAAH/2gAIAQIBAT8QWP/EABwQAQACAwEBAQAAAAAAAAAAAAEAESFBYTFxgf/aAAgBAQABPxDDsqnLw0Q2MPyYNl2uLNPWvmoQE0ulXfIo9TBvHJ//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/510e12237c5b2aad28eb957ec94060f8/d2432/ranksense-problems.webp 314w", "/static/510e12237c5b2aad28eb957ec94060f8/41786/ranksense-problems.webp 628w", "/static/510e12237c5b2aad28eb957ec94060f8/d4599/ranksense-problems.webp 1256w", "/static/510e12237c5b2aad28eb957ec94060f8/72bc3/ranksense-problems.webp 1884w", "/static/510e12237c5b2aad28eb957ec94060f8/51747/ranksense-problems.webp 2512w", "/static/510e12237c5b2aad28eb957ec94060f8/4e71a/ranksense-problems.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/510e12237c5b2aad28eb957ec94060f8/7a743/ranksense-problems.jpg 314w", "/static/510e12237c5b2aad28eb957ec94060f8/6f105/ranksense-problems.jpg 628w", "/static/510e12237c5b2aad28eb957ec94060f8/10d53/ranksense-problems.jpg 1256w", "/static/510e12237c5b2aad28eb957ec94060f8/b2e4b/ranksense-problems.jpg 1884w", "/static/510e12237c5b2aad28eb957ec94060f8/fdcb3/ranksense-problems.jpg 2512w", "/static/510e12237c5b2aad28eb957ec94060f8/78bd4/ranksense-problems.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/510e12237c5b2aad28eb957ec94060f8/10d53/ranksense-problems.jpg",
            "alt": "User Experience Design Problems - UX Design | RankSense",
            "title": "User Experience Design Problems - UX Design | RankSense",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAECBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHeJaUIP//EABUQAQEAAAAAAAAAAAAAAAAAABAR/9oACAEBAAEFAmR//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQAGPwJf/8QAGhAAAgIDAAAAAAAAAAAAAAAAAAEQESFBgf/aAAgBAQABPyE7FGbWzEf/2gAMAwEAAgADAAAAEBw//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAHBABAAMAAgMAAAAAAAAAAAAAAQARIUFRYXGR/9oACAEBAAE/EBFx+Qzk+4Nm5C0F4YWfEXqf/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/46a46b3fa06f333741f4bd833a2b8dff/d2432/ranksense-workflow.webp 314w", "/static/46a46b3fa06f333741f4bd833a2b8dff/41786/ranksense-workflow.webp 628w", "/static/46a46b3fa06f333741f4bd833a2b8dff/d4599/ranksense-workflow.webp 1256w", "/static/46a46b3fa06f333741f4bd833a2b8dff/72bc3/ranksense-workflow.webp 1884w", "/static/46a46b3fa06f333741f4bd833a2b8dff/51747/ranksense-workflow.webp 2512w", "/static/46a46b3fa06f333741f4bd833a2b8dff/4e71a/ranksense-workflow.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/46a46b3fa06f333741f4bd833a2b8dff/7a743/ranksense-workflow.jpg 314w", "/static/46a46b3fa06f333741f4bd833a2b8dff/6f105/ranksense-workflow.jpg 628w", "/static/46a46b3fa06f333741f4bd833a2b8dff/10d53/ranksense-workflow.jpg 1256w", "/static/46a46b3fa06f333741f4bd833a2b8dff/b2e4b/ranksense-workflow.jpg 1884w", "/static/46a46b3fa06f333741f4bd833a2b8dff/fdcb3/ranksense-workflow.jpg 2512w", "/static/46a46b3fa06f333741f4bd833a2b8dff/78bd4/ranksense-workflow.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/46a46b3fa06f333741f4bd833a2b8dff/10d53/ranksense-workflow.jpg",
            "alt": "Workflow and Timeline - UX Design | RankSense",
            "title": "Workflow and Timeline - UX Design | RankSense",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAUD/8QAFQEBAQAAAAAAAAAAAAAAAAAAAgP/2gAMAwEAAhADEAAAAc7kevQbia//xAAZEAACAwEAAAAAAAAAAAAAAAAAAwECFBL/2gAIAQEAAQUCzNF15g2wKvDKn//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAEDAQE/AQJ//8QAFxEBAAMAAAAAAAAAAAAAAAAAAQIQMf/aAAgBAgEBPwGSu1//xAAcEAABAwUAAAAAAAAAAAAAAAAAARARMTIzkaH/2gAIAQEABj8CtTZRsXSUb//EABsQAAICAwEAAAAAAAAAAAAAAAABESEQMUFR/9oACAEBAAE/IayT6gr5hS6wGjiy/T//2gAMAwEAAgADAAAAECs//8QAFhEBAQEAAAAAAAAAAAAAAAAAARAh/9oACAEDAQE/ECOT/8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQIBAT8QKj//xAAcEAACAgMBAQAAAAAAAAAAAAABEQAxIUFRcZH/2gAIAQEAAT8QGWUtNHa7H2yyTkwNQ50EAF7uoiABZvkWofJ//9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/75f1b8fa2f750549bb1de7503619c5b0/d2432/ranksense-ui.webp 314w", "/static/75f1b8fa2f750549bb1de7503619c5b0/41786/ranksense-ui.webp 628w", "/static/75f1b8fa2f750549bb1de7503619c5b0/d4599/ranksense-ui.webp 1256w", "/static/75f1b8fa2f750549bb1de7503619c5b0/72bc3/ranksense-ui.webp 1884w", "/static/75f1b8fa2f750549bb1de7503619c5b0/51747/ranksense-ui.webp 2512w", "/static/75f1b8fa2f750549bb1de7503619c5b0/4e71a/ranksense-ui.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/75f1b8fa2f750549bb1de7503619c5b0/7a743/ranksense-ui.jpg 314w", "/static/75f1b8fa2f750549bb1de7503619c5b0/6f105/ranksense-ui.jpg 628w", "/static/75f1b8fa2f750549bb1de7503619c5b0/10d53/ranksense-ui.jpg 1256w", "/static/75f1b8fa2f750549bb1de7503619c5b0/b2e4b/ranksense-ui.jpg 1884w", "/static/75f1b8fa2f750549bb1de7503619c5b0/fdcb3/ranksense-ui.jpg 2512w", "/static/75f1b8fa2f750549bb1de7503619c5b0/78bd4/ranksense-ui.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/75f1b8fa2f750549bb1de7503619c5b0/10d53/ranksense-ui.jpg",
            "alt": "User Interface Design - UX Design | RankSense",
            "title": "User Interface Design - UX Design | RankSense",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAECBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHuWLDI/8QAFxABAQEBAAAAAAAAAAAAAAAAABEBEP/aAAgBAQABBQLtxVf/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAY/Al//xAAaEAEBAAIDAAAAAAAAAAAAAAABABFBECFR/9oACAEBAAE/IQLrWOACx9gGr//aAAwDAQACAAMAAAAQEO//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPxBX/8QAFxEBAAMAAAAAAAAAAAAAAAAAAAERIf/aAAgBAgEBPxDVS//EABwQAAICAgMAAAAAAAAAAAAAAAERACExUXGBkf/aAAgBAQABPxCo01qGhsPErY6gCGaGo05eQArT/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/cc222311433a4a3cb591ecc745a845db/d2432/ranksense-hifi1.webp 314w", "/static/cc222311433a4a3cb591ecc745a845db/41786/ranksense-hifi1.webp 628w", "/static/cc222311433a4a3cb591ecc745a845db/d4599/ranksense-hifi1.webp 1256w", "/static/cc222311433a4a3cb591ecc745a845db/72bc3/ranksense-hifi1.webp 1884w", "/static/cc222311433a4a3cb591ecc745a845db/51747/ranksense-hifi1.webp 2512w", "/static/cc222311433a4a3cb591ecc745a845db/4e71a/ranksense-hifi1.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/cc222311433a4a3cb591ecc745a845db/7a743/ranksense-hifi1.jpg 314w", "/static/cc222311433a4a3cb591ecc745a845db/6f105/ranksense-hifi1.jpg 628w", "/static/cc222311433a4a3cb591ecc745a845db/10d53/ranksense-hifi1.jpg 1256w", "/static/cc222311433a4a3cb591ecc745a845db/b2e4b/ranksense-hifi1.jpg 1884w", "/static/cc222311433a4a3cb591ecc745a845db/fdcb3/ranksense-hifi1.jpg 2512w", "/static/cc222311433a4a3cb591ecc745a845db/78bd4/ranksense-hifi1.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/cc222311433a4a3cb591ecc745a845db/10d53/ranksense-hifi1.jpg",
            "alt": "High Fidelity Prototype - UX Design | RankSense",
            "title": "High Fidelity Prototype - UX Design | RankSense",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAQBAgMF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAwEC/9oADAMBAAIQAxAAAAHPpxVTQFjU/8QAGhAAAgMBAQAAAAAAAAAAAAAAAQIDBBMAEv/aAAgBAQABBQKGLR1rIBMio9aVSlixmfRPf//EABcRAQEBAQAAAAAAAAAAAAAAAAEAESH/2gAIAQMBAT8BAy5f/8QAFxEBAAMAAAAAAAAAAAAAAAAAAQACEf/aAAgBAgEBPwGyrky0/8QAHRAAAgEEAwAAAAAAAAAAAAAAAAERAhIhIjEyQv/aAAgBAQAGPwKHwQ9iEKj0WrsZP//EABoQAQEAAwEBAAAAAAAAAAAAAAERACExQXH/2gAIAQEAAT8hUKHTjue3eJgqkS7x4KDyZXPSO+THbi/M/9oADAMBAAIAAwAAABDEH//EABcRAQEBAQAAAAAAAAAAAAAAAAEAEVH/2gAIAQMBAT8QJpl4v//EABcRAAMBAAAAAAAAAAAAAAAAAAABETH/2gAIAQIBAT8QhAoaf//EABwQAAMBAAIDAAAAAAAAAAAAAAERIQAxUUFx0f/aAAgBAQABPxCQLJOCuxLVhGcgGEPYz4YNGqfmbNAMVvg6ynAaJu948CEATTf/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/ea3edf251bdcc0da67d5d79a641f6c36/d2432/ranksense-hifi2.webp 314w", "/static/ea3edf251bdcc0da67d5d79a641f6c36/41786/ranksense-hifi2.webp 628w", "/static/ea3edf251bdcc0da67d5d79a641f6c36/d4599/ranksense-hifi2.webp 1256w", "/static/ea3edf251bdcc0da67d5d79a641f6c36/72bc3/ranksense-hifi2.webp 1884w", "/static/ea3edf251bdcc0da67d5d79a641f6c36/51747/ranksense-hifi2.webp 2512w", "/static/ea3edf251bdcc0da67d5d79a641f6c36/4e71a/ranksense-hifi2.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/ea3edf251bdcc0da67d5d79a641f6c36/7a743/ranksense-hifi2.jpg 314w", "/static/ea3edf251bdcc0da67d5d79a641f6c36/6f105/ranksense-hifi2.jpg 628w", "/static/ea3edf251bdcc0da67d5d79a641f6c36/10d53/ranksense-hifi2.jpg 1256w", "/static/ea3edf251bdcc0da67d5d79a641f6c36/b2e4b/ranksense-hifi2.jpg 1884w", "/static/ea3edf251bdcc0da67d5d79a641f6c36/fdcb3/ranksense-hifi2.jpg 2512w", "/static/ea3edf251bdcc0da67d5d79a641f6c36/78bd4/ranksense-hifi2.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/ea3edf251bdcc0da67d5d79a641f6c36/10d53/ranksense-hifi2.jpg",
            "alt": "Mockups - UX Design | RankSense",
            "title": "Mockups - UX Design | RankSense",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAECBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHcVOyRh//EABQQAQAAAAAAAAAAAAAAAAAAACD/2gAIAQEAAQUCX//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABQQAQAAAAAAAAAAAAAAAAAAACD/2gAIAQEABj8CX//EABoQAAIDAQEAAAAAAAAAAAAAAAERABBBITH/2gAIAQEAAT8hOKF4vaCnK//aAAwDAQACAAMAAAAQhx//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPxCH/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAHBABAQEAAgMBAAAAAAAAAAAAAREAIXExQVFh/9oACAEBAAE/EAgx5S9ZkEVRFJ+Zm5LAfb93bIXnf//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/c6d873f0a3c5604b7c66d9327001dc00/d2432/ranksense-hifi3.webp 314w", "/static/c6d873f0a3c5604b7c66d9327001dc00/41786/ranksense-hifi3.webp 628w", "/static/c6d873f0a3c5604b7c66d9327001dc00/d4599/ranksense-hifi3.webp 1256w", "/static/c6d873f0a3c5604b7c66d9327001dc00/72bc3/ranksense-hifi3.webp 1884w", "/static/c6d873f0a3c5604b7c66d9327001dc00/51747/ranksense-hifi3.webp 2512w", "/static/c6d873f0a3c5604b7c66d9327001dc00/4e71a/ranksense-hifi3.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/c6d873f0a3c5604b7c66d9327001dc00/7a743/ranksense-hifi3.jpg 314w", "/static/c6d873f0a3c5604b7c66d9327001dc00/6f105/ranksense-hifi3.jpg 628w", "/static/c6d873f0a3c5604b7c66d9327001dc00/10d53/ranksense-hifi3.jpg 1256w", "/static/c6d873f0a3c5604b7c66d9327001dc00/b2e4b/ranksense-hifi3.jpg 1884w", "/static/c6d873f0a3c5604b7c66d9327001dc00/fdcb3/ranksense-hifi3.jpg 2512w", "/static/c6d873f0a3c5604b7c66d9327001dc00/78bd4/ranksense-hifi3.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/c6d873f0a3c5604b7c66d9327001dc00/10d53/ranksense-hifi3.jpg",
            "alt": "Prototyping - UX Design | RankSense",
            "title": "Prototyping - UX Design | RankSense",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAECAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB1VFWWDD/xAAaEAADAQADAAAAAAAAAAAAAAAAAQISAxMh/9oACAEBAAEFAs8g9o0ztsd3Q/T/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAeEAACAQMFAAAAAAAAAAAAAAAAARECEjETITOBkv/aAAgBAQAGPwLNJLtN6WcL9IjSjswf/8QAGxABAAIDAQEAAAAAAAAAAAAAAQARITGREPH/2gAIAQEAAT8h+Kw02CwwMo0OeIUaQszWJW/Z/9oADAMBAAIAAwAAABCnL//EABURAQEAAAAAAAAAAAAAAAAAABAh/9oACAEDAQE/EIf/xAAVEQEBAAAAAAAAAAAAAAAAAAAAIf/aAAgBAgEBPxCq/8QAGxABAQEBAAMBAAAAAAAAAAAAAREhADFRgfD/2gAIAQEAAT8Qpsfz749LUBHWZXoQQmCrPj2My4MfzLWA3wcWYQmjv//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/eb1ff22a34e24f316d238c42c9ecd0e1/d2432/ranksense-hifi4.webp 314w", "/static/eb1ff22a34e24f316d238c42c9ecd0e1/41786/ranksense-hifi4.webp 628w", "/static/eb1ff22a34e24f316d238c42c9ecd0e1/d4599/ranksense-hifi4.webp 1256w", "/static/eb1ff22a34e24f316d238c42c9ecd0e1/72bc3/ranksense-hifi4.webp 1884w", "/static/eb1ff22a34e24f316d238c42c9ecd0e1/51747/ranksense-hifi4.webp 2512w", "/static/eb1ff22a34e24f316d238c42c9ecd0e1/4e71a/ranksense-hifi4.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/eb1ff22a34e24f316d238c42c9ecd0e1/7a743/ranksense-hifi4.jpg 314w", "/static/eb1ff22a34e24f316d238c42c9ecd0e1/6f105/ranksense-hifi4.jpg 628w", "/static/eb1ff22a34e24f316d238c42c9ecd0e1/10d53/ranksense-hifi4.jpg 1256w", "/static/eb1ff22a34e24f316d238c42c9ecd0e1/b2e4b/ranksense-hifi4.jpg 1884w", "/static/eb1ff22a34e24f316d238c42c9ecd0e1/fdcb3/ranksense-hifi4.jpg 2512w", "/static/eb1ff22a34e24f316d238c42c9ecd0e1/78bd4/ranksense-hifi4.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/eb1ff22a34e24f316d238c42c9ecd0e1/10d53/ranksense-hifi4.jpg",
            "alt": "User Journey - UX Design | RankSense",
            "title": "User Journey - UX Design | RankSense",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQBAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAACA//aAAwDAQACEAMQAAABV1KFrykXGf/EABsQAAMAAgMAAAAAAAAAAAAAAAECAwAEEBES/9oACAEBAAEFAgpOCKKtJqrT2ehV/XH/xAAVEQEBAAAAAAAAAAAAAAAAAAAQIf/aAAgBAwEBPwGH/8QAFhEBAQEAAAAAAAAAAAAAAAAAARAS/9oACAECAQE/AXTP/8QAHhAAAQQBBQAAAAAAAAAAAAAAAQADERICEDFBUYL/2gAIAQEABj8CEDflVcHpQHAVVwTioxmnWn//xAAcEAEAAgMAAwAAAAAAAAAAAAABACERMUEQkeH/2gAIAQEAAT8h4ZWBUe5RmvLXyKyUrfq1MuiNN8f/2gAMAwEAAgADAAAAEKcP/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARUf/aAAgBAwEBPxAybKcv/8QAFxEBAAMAAAAAAAAAAAAAAAAAAQARMf/aAAgBAgEBPxAJWQGtn//EAB8QAQEAAgIBBQAAAAAAAAAAAAERACFBYRAxUYHB0f/aAAgBAQABPxA8AciTtaydsGwr5+xMJgOUadMEyVAtgUOz0cC8pW0vv+G54//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/c38b89366b2a7fa459577d0fa2ec0428/d2432/ranksense-hifi5.webp 314w", "/static/c38b89366b2a7fa459577d0fa2ec0428/41786/ranksense-hifi5.webp 628w", "/static/c38b89366b2a7fa459577d0fa2ec0428/d4599/ranksense-hifi5.webp 1256w", "/static/c38b89366b2a7fa459577d0fa2ec0428/72bc3/ranksense-hifi5.webp 1884w", "/static/c38b89366b2a7fa459577d0fa2ec0428/51747/ranksense-hifi5.webp 2512w", "/static/c38b89366b2a7fa459577d0fa2ec0428/4e71a/ranksense-hifi5.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/c38b89366b2a7fa459577d0fa2ec0428/7a743/ranksense-hifi5.jpg 314w", "/static/c38b89366b2a7fa459577d0fa2ec0428/6f105/ranksense-hifi5.jpg 628w", "/static/c38b89366b2a7fa459577d0fa2ec0428/10d53/ranksense-hifi5.jpg 1256w", "/static/c38b89366b2a7fa459577d0fa2ec0428/b2e4b/ranksense-hifi5.jpg 1884w", "/static/c38b89366b2a7fa459577d0fa2ec0428/fdcb3/ranksense-hifi5.jpg 2512w", "/static/c38b89366b2a7fa459577d0fa2ec0428/78bd4/ranksense-hifi5.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/c38b89366b2a7fa459577d0fa2ec0428/10d53/ranksense-hifi5.jpg",
            "alt": "User Flow - UX Design | RankSense",
            "title": "User Flow - UX Design | RankSense",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAEE/9oADAMBAAIQAxAAAAFUrNGifLLxP//EABsQAQACAgMAAAAAAAAAAAAAAAECAwAEERIT/9oACAEBAAEFAuijBUrchsSgath53Wc2f//EABcRAQEBAQAAAAAAAAAAAAAAAAEAAhL/2gAIAQMBAT8Bycy3/8QAFREBAQAAAAAAAAAAAAAAAAAAAQD/2gAIAQIBAT8BWC//xAAZEAADAQEBAAAAAAAAAAAAAAAAAREhMUH/2gAIAQEABj8CqRwwaXpI2Yf/xAAaEAADAQEBAQAAAAAAAAAAAAAAAREhUYGR/9oACAEBAAE/IU9oS4XNsmx9GcrqoQ9zg5kRLIz/2gAMAwEAAgADAAAAEBcv/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAR/9oACAEDAQE/EBQrDrf/xAAXEQEBAQEAAAAAAAAAAAAAAAABABEh/9oACAECAQE/EFR5Jl//xAAcEAEBAAICAwAAAAAAAAAAAAABEQAhQVFhofD/2gAIAQEAAT8Q5+EQ6xGQpaF18YtMS8o5HbSK71pwnUGgO3lxfnCAvrWf/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/cda4d8a23cdb8de99edf96b13d6b087c/d2432/ranksense-site.webp 314w", "/static/cda4d8a23cdb8de99edf96b13d6b087c/41786/ranksense-site.webp 628w", "/static/cda4d8a23cdb8de99edf96b13d6b087c/d4599/ranksense-site.webp 1256w", "/static/cda4d8a23cdb8de99edf96b13d6b087c/72bc3/ranksense-site.webp 1884w", "/static/cda4d8a23cdb8de99edf96b13d6b087c/51747/ranksense-site.webp 2512w", "/static/cda4d8a23cdb8de99edf96b13d6b087c/4e71a/ranksense-site.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/cda4d8a23cdb8de99edf96b13d6b087c/7a743/ranksense-site.jpg 314w", "/static/cda4d8a23cdb8de99edf96b13d6b087c/6f105/ranksense-site.jpg 628w", "/static/cda4d8a23cdb8de99edf96b13d6b087c/10d53/ranksense-site.jpg 1256w", "/static/cda4d8a23cdb8de99edf96b13d6b087c/b2e4b/ranksense-site.jpg 1884w", "/static/cda4d8a23cdb8de99edf96b13d6b087c/fdcb3/ranksense-site.jpg 2512w", "/static/cda4d8a23cdb8de99edf96b13d6b087c/78bd4/ranksense-site.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/cda4d8a23cdb8de99edf96b13d6b087c/10d53/ranksense-site.jpg",
            "alt": "User Experience Design - UX Design | RankSense",
            "title": "User Experience Design - UX Design | RankSense",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAIDBf/EABUBAQEAAAAAAAAAAAAAAAAAAAEA/9oADAMBAAIQAxAAAAHuTcJoC//EABgQAAIDAAAAAAAAAAAAAAAAAAABEBIx/9oACAEBAAEFAoWFRH//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAY/Al//xAAaEAACAwEBAAAAAAAAAAAAAAABEQAQMSFB/9oACAEBAAE/IS3s92p9oCCn/9oADAMBAAIAAwAAABCQP//EABURAQEAAAAAAAAAAAAAAAAAAAAB/9oACAEDAQE/EKj/xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQIBAT8QmK//xAAbEAEAAwADAQAAAAAAAAAAAAABABEhMVFhcf/aAAgBAQABPxDWmeoCpablPswAbDI0QxbxWu4T56f/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/b95cb88ae5682676b122418b18dfcf16/d2432/ranksense-handoff.webp 314w", "/static/b95cb88ae5682676b122418b18dfcf16/41786/ranksense-handoff.webp 628w", "/static/b95cb88ae5682676b122418b18dfcf16/d4599/ranksense-handoff.webp 1256w", "/static/b95cb88ae5682676b122418b18dfcf16/72bc3/ranksense-handoff.webp 1884w", "/static/b95cb88ae5682676b122418b18dfcf16/51747/ranksense-handoff.webp 2512w", "/static/b95cb88ae5682676b122418b18dfcf16/4e71a/ranksense-handoff.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/b95cb88ae5682676b122418b18dfcf16/7a743/ranksense-handoff.jpg 314w", "/static/b95cb88ae5682676b122418b18dfcf16/6f105/ranksense-handoff.jpg 628w", "/static/b95cb88ae5682676b122418b18dfcf16/10d53/ranksense-handoff.jpg 1256w", "/static/b95cb88ae5682676b122418b18dfcf16/b2e4b/ranksense-handoff.jpg 1884w", "/static/b95cb88ae5682676b122418b18dfcf16/fdcb3/ranksense-handoff.jpg 2512w", "/static/b95cb88ae5682676b122418b18dfcf16/78bd4/ranksense-handoff.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/b95cb88ae5682676b122418b18dfcf16/10d53/ranksense-handoff.jpg",
            "alt": "Design Handoff - UX Design | RankSense",
            "title": "Design Handoff - UX Design | RankSense",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      